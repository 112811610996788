// Gotham Pro
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro-Black.ttf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("./assets/fonts/GothamPro/GothamPro-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}


// Source Sans Pro
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-ExtraLightItalic.ttf");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Black.ttf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}


// Consolas
@font-face {
  font-family: "Consolas";
  src: url("./assets/fonts/Consolas/Consolas.ttf");
  font-weight: normal;
  font-style: normal;
}


// Luminari
@font-face {
  font-family: "Luminari";
  src: url("./assets/fonts/Luminari/Luminari-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}


body,
html {
  height: 100%;
  margin: 0;
  font-family: "Gotham Pro";
  // font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  user-select: text;
}

/* define height for wrapper's parent div */
div#root {
  height: 100%;
}

/* Hide scrollbar for all browsers */
body::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera*/
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
}

.featuredInCardCssGrid {
  grid-area: card;
  width: 320px; /* Need a specific value to work */
  height: 460px;
}

.tinderCards {
  user-select: none;
  cursor: grab;
  z-index: 1;
}

@media (max-width: 1200px) {
  .featuredInCardCssGrid {
    height: 370px;
  }
}